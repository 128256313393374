// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.promos {
  width: 100%;
  height: 100vh;
}
.promos-slider {
  width: 100%;
  padding: 60px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
}
.promos-slider-title {
  font-size: 32px;
  font-weight: 700;
  color: #fefefe;
  text-align: center;
}
.promos-slider-descr {
  margin-top: 16px;
  font-size: 14px;
  font-weight: 400;
  color: #898989;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/PromosPage/style.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;AACJ;AAAI;EACI,WAAA;EACA,eAAA;EACA,sBAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;AAER;AADQ;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;AAGV;AADQ;EACE,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;AAGV","sourcesContent":[".promos {\n    width: 100%;\n    height: 100vh;\n    &-slider {\n        width: 100%;\n        padding: 60px 0;\n        box-sizing: border-box;\n        display: flex;\n        flex-direction: column;\n        position: relative;\n        &-title {\n          font-size: 32px;\n          font-weight: 700;\n          color: #fefefe;\n          text-align: center;\n        }\n        &-descr {\n          margin-top: 16px;\n          font-size: 14px;\n          font-weight: 400;\n          color: #898989;\n          text-align: center;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
