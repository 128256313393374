import React from 'react'

const Burger: React.FC = () => {
    return (
        <svg width="32px" height="32px" viewBox="0 0 24 24" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 18L20 18" stroke="#ffffff" stroke-width="2" stroke-linecap="round" />
            <path d="M4 12L20 12" stroke="#ffffff" stroke-width="2" stroke-linecap="round" />
            <path d="M4 6L20 6" stroke="#ffffff" stroke-width="2" stroke-linecap="round" />
        </svg>
    )
}

export default Burger