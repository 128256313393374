import React, { useEffect, useState } from "react";
import "./style.scss";
import { t } from "../../resources/t";
import { CategoryTypes } from "../../models";
import axios from "../../axios";
import { useNavigate } from "react-router-dom";

interface Props {
  categories: CategoryTypes[];
}

const CategoriesDrawer: React.FC<Props> = ({ categories }) => {
  const navigate = useNavigate();
  return (
    <div className="drawer">
      <div className="drawer-title">{t("Все категории")}</div>
      {categories.map((item: any) => (
        <span key={item.id} onClick={() => navigate(`/categories/${item.id}`, { state: {category_id: item.id} })}>
          {item[`name_${localStorage.cur_lang}`]}
        </span>
      ))}
    </div>
  );
};

export default CategoriesDrawer;
