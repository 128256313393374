import React, { useEffect, useMemo, useState } from "react";
import Header from "../../components/Header";
import "./style.scss";
import { t } from "../../resources/t";
import Footer from "../../components/Footer";
import Slider from "../../components/Slider";
import axios from "../../axios";
import { CategoryTypes, ProductsTypes } from "../../models";
import Loading from "../../components/Loading";
import { useNavigate } from "react-router-dom";

const MainPage: React.FC = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<ProductsTypes[]>([]);
  const [categories, setCategories] = useState<CategoryTypes[] | any>([]);
  const [mainProduct, setMainProduct] = useState<ProductsTypes | any>(null);

  const sorted_products = useMemo(() => {
    return products.filter(item => item.is_popular === true);
  }, [products]);

  useEffect(() => {
    setLoading(true);
    axios
      .get("/client/category_list/")
      .then((response) => {
        setCategories(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    axios.get("/client/product_list/?is_main_product=true").then((response) => {
      setMainProduct(response.data[0]);
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    axios.get("/client/product_list/").then((response) => {
      setProducts(response.data);
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  return (
    <>
      <div className="main">
        {loading && <Loading />}
        <div className="main-bgvideo">
          <Header />
          <div className="main-bgvideo-button container">
            <div className="main-bgvideo-button-left">
              <div className="main-bgvideo-button-left-img">
                <img src={
                  mainProduct?.image ?
                    `${process.env.REACT_APP_API_BASE_URL}${mainProduct.image}` :
                    require("../../assets/images/image1.png")
                } alt="" />
              </div>
              <div className="main-bgvideo-button-left-text">
                {mainProduct && mainProduct[`name_${localStorage.cur_lang}`]}
              </div>
            </div>
            <button
              onClick={() =>
                navigate(`/product/${mainProduct.id}`, {
                  state: { product_id: mainProduct.id },
                })
              }
            >
              <span>{t("Подробнее")}</span>
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.46968 13.5292C5.32923 13.3886 5.25034 13.1979 5.25034 12.9992C5.25034 12.8004 5.32923 12.6098 5.46968 12.4692L9.43968 8.49918L5.46968 4.52918C5.396 4.46052 5.33689 4.37771 5.2959 4.28571C5.25491 4.19372 5.23287 4.0944 5.23109 3.9937C5.22931 3.893 5.24784 3.79297 5.28556 3.69958C5.32328 3.60619 5.37943 3.52136 5.45065 3.45014C5.52186 3.37892 5.6067 3.32278 5.70009 3.28505C5.79347 3.24733 5.8935 3.22881 5.99421 3.23059C6.09491 3.23236 6.19422 3.2544 6.28622 3.2954C6.37822 3.33639 6.46102 3.39549 6.52968 3.46918L11.0297 7.96918C11.1701 8.1098 11.249 8.30043 11.249 8.49918C11.249 8.69793 11.1701 8.88855 11.0297 9.02918L6.52968 13.5292C6.38906 13.6696 6.19843 13.7485 5.99968 13.7485C5.80093 13.7485 5.61031 13.6696 5.46968 13.5292Z"
                  fill="#FEFEFE"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="main-slider">
          <div className="main-slider-title">
            {t("Популярные товары/Рекомендуемые")}
          </div>
          <div className="main-slider-descr">
            {t(
              "Ознакомьтесь с нашими самыми востребованными товарами, которые клиенты выбирают чаще всего."
            )}
          </div>
          <Slider products={sorted_products} />
          <div className="main-slider-categories container">
            <div className="main-slider-categories-row">
              <div className="main-slider-categories-row-item">
                <img src={`${process.env.REACT_APP_API_BASE_URL}${categories[0]?.image}`} alt="" width={306} height={320} />
                <div className="main-slider-categories-row-item-name">
                  {categories[0] && categories[0][`name_${localStorage.cur_lang}`]}
                </div>
              </div>
              <div className="main-slider-categories-row-item">
                <img src={`${process.env.REACT_APP_API_BASE_URL}${categories[1]?.image}`} alt="" width={306} height={320} />
                <div className="main-slider-categories-row-item-name">
                  {categories[1] && categories[1][`name_${localStorage.cur_lang}`]}
                </div>
              </div>
              <div
                className="main-slider-categories-row-item"
                style={{ width: "50%" }}
              >
                <img src={`${process.env.REACT_APP_API_BASE_URL}${categories[2]?.image}`} alt="" height={320} />
                <div className="main-slider-categories-row-item-name">
                  {categories[2] && categories[2][`name_${localStorage.cur_lang}`]}
                </div>
              </div>
            </div>
            <div className="main-slider-categories-row">
              <div
                className="main-slider-categories-row-item"
                style={{ width: "50%" }}
              >
                <img src={`${process.env.REACT_APP_API_BASE_URL}${categories[3]?.image}`} width={640} height={670} alt="" />
                <div className="main-slider-categories-row-item-name">
                  {categories[3] && categories[3][`name_${localStorage.cur_lang}`]}
                </div>
              </div>
              <div className="main-slider-categories-row-block">
                <div className="main-slider-categories-row-block-item">
                  <img src={`${process.env.REACT_APP_API_BASE_URL}${categories[4]?.image}`} alt="" width={306} height={320} />
                  <div className="main-slider-categories-row-item-name">
                    {categories[4] && categories[4][`name_${localStorage.cur_lang}`]}
                  </div>
                </div>
                <div className="main-slider-categories-row-block-item">
                  <img src={`${process.env.REACT_APP_API_BASE_URL}${categories[5]?.image}`} alt="" width={306} height={320} />
                  <div className="main-slider-categories-row-item-name">
                    {categories[5] && categories[5][`name_${localStorage.cur_lang}`]}
                  </div>
                </div>
                <div className="main-slider-categories-row-block-item">
                  <img src={`${process.env.REACT_APP_API_BASE_URL}${categories[6]?.image}`} alt="" width={306} height={320} />
                  <div className="main-slider-categories-row-item-name">
                    {categories[6] && categories[6][`name_${localStorage.cur_lang}`]}
                  </div>
                </div>
                <div className="main-slider-categories-row-block-item">
                  <img src={`${process.env.REACT_APP_API_BASE_URL}${categories[7]?.image}`} alt="" width={306} height={320} />
                  <div className="main-slider-categories-row-item-name">
                    {categories[7] && categories[7][`name_${localStorage.cur_lang}`]}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-bgimage">
            <div className="bottom-bgimage-title">
              {t("Откройте для себя свой стиль")}
            </div>
            <div className="bottom-bgimage-descr">
              {t(
                "Ziffler – современный, доступный бренд, который развивается и предлагает фирменную бытовую технику для Вашего дома."
              )}
            </div>
            <button>
              <span>{t("Подробнее")}</span>
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.46968 13.5292C5.32923 13.3886 5.25034 13.1979 5.25034 12.9992C5.25034 12.8004 5.32923 12.6098 5.46968 12.4692L9.43968 8.49918L5.46968 4.52918C5.396 4.46052 5.33689 4.37771 5.2959 4.28571C5.25491 4.19372 5.23287 4.0944 5.23109 3.9937C5.22931 3.893 5.24784 3.79297 5.28556 3.69958C5.32328 3.60619 5.37943 3.52136 5.45065 3.45014C5.52186 3.37892 5.6067 3.32278 5.70009 3.28505C5.79347 3.24733 5.8935 3.22881 5.99421 3.23059C6.09491 3.23236 6.19422 3.2544 6.28622 3.2954C6.37822 3.33639 6.46102 3.39549 6.52968 3.46918L11.0297 7.96918C11.1701 8.1098 11.249 8.30043 11.249 8.49918C11.249 8.69793 11.1701 8.88855 11.0297 9.02918L6.52968 13.5292C6.38906 13.6696 6.19843 13.7485 5.99968 13.7485C5.80093 13.7485 5.61031 13.6696 5.46968 13.5292Z"
                  fill="#FEFEFE"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MainPage;
