import React from 'react'

const FlagEn: React.FC = () => {
    return (
        <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_123_1284)">
                <path d="M22 0.429688H2C0.89543 0.429688 0 1.32512 0 2.42969V15.5726C0 16.6772 0.89543 17.5726 2 17.5726H22C23.1046 17.5726 24 16.6772 24 15.5726V2.42969C24 1.32512 23.1046 0.429688 22 0.429688Z" fill="white" />
                <mask id="mask0_123_1284" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="18">
                    <path d="M22 0.429688H2C0.89543 0.429688 0 1.32512 0 2.42969V15.5726C0 16.6772 0.89543 17.5726 2 17.5726H22C23.1046 17.5726 24 16.6772 24 15.5726V2.42969C24 1.32512 23.1046 0.429688 22 0.429688Z" fill="white" />
                </mask>
                <g mask="url(#mask0_123_1284)">
                    <path d="M24 0.429688H0V17.5726H24V0.429688Z" fill="#0A17A7" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M-1.0988 -1.21289L9.14332 5.69551V-0.713077H14.8576V5.69551L25.0997 -1.21289L26.3779 0.682054L18.2801 6.14407H24.0005V11.8584H18.2801L26.3779 17.3204L25.0997 19.2153L14.8576 12.3069V18.7155H9.14332V12.3069L-1.0988 19.2153L-2.37695 17.3204L5.72081 11.8584H0.000458241V6.14407H5.72081L-2.37695 0.682055L-1.0988 -1.21289Z" fill="white" />
                    <path d="M16.001 5.85673L26.857 -1.28516" stroke="#DB1F35" stroke-width="0.666667" stroke-linecap="round" />
                    <path d="M17.1543 12.168L26.8862 18.7276" stroke="#DB1F35" stroke-width="0.666667" stroke-linecap="round" />
                    <path d="M6.86209 5.83733L-3.28906 -1.00391" stroke="#DB1F35" stroke-width="0.666667" stroke-linecap="round" />
                    <path d="M7.9631 12.0898L-3.28906 19.5518" stroke="#DB1F35" stroke-width="0.666667" stroke-linecap="round" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 10.7154H10.2857V17.5726H13.7143V10.7154H24V7.28683H13.7143V0.429688H10.2857V7.28683H0V10.7154Z" fill="#E6273E" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_123_1284">
                    <rect width="24" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default FlagEn