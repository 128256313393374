import React, { useEffect, useState } from 'react'
import "./style.scss"
import Header from '../../components/Header'
import { t } from '../../resources/t';
import Slider from '../../components/Slider';
import axios from '../../axios';
import Loading from '../../components/Loading';

const PromosPage: React.FC = () => {
    const [loadnig, setLoading] = useState(false);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        setLoading(true);
        axios.get("/client/product_list/?is_promotion=true")
          .then(response => {
            setProducts(response.data);
          }).finally(() => setLoading(false));
    }, []);

    if (loadnig) return <Loading />;
    
    return (
        <div className='promos'>
            <Header />
            <div className="promos-slider">
                <div className="promos-slider-title">
                    {t("Товары по акции")}
                </div>
                <div className="promos-slider-descr">
                    {t(
                        "Ознакомьтесь с нашими товарами по акции."
                    )}
                </div>
                <Slider products={products} />
            </div>
        </div>
    );
};

export default PromosPage;