import React from 'react'

const FlagRu: React.FC = () => {
    return (
        <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_123_1525)">
                <path d="M0 0H24V6H0V0Z" fill="white" />
                <path d="M0 6H24V12H0V6Z" fill="#0039A6" />
                <path d="M0 12H24V18H0V12Z" fill="#D52B1E" />
            </g>
            <defs>
                <clipPath id="clip0_123_1525">
                    <rect width="24" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default FlagRu;