import React from 'react'

const FlagUz: React.FC = () => {
    return (
        <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_123_1297)">
                <path d="M0 12H24V18H0V12Z" fill="#1EB53A" />
                <path d="M0 0H24V6H0V0Z" fill="#0099B5" />
                <path d="M0 5.75977H24V12.2398H0V5.75977Z" fill="#CE1126" />
                <path d="M0 6.11914H24V11.8791H0V6.11914Z" fill="white" />
                <path d="M5.03988 5.0407C6.23282 5.0407 7.19988 4.07364 7.19988 2.8807C7.19988 1.68777 6.23282 0.720703 5.03988 0.720703C3.84695 0.720703 2.87988 1.68777 2.87988 2.8807C2.87988 4.07364 3.84695 5.0407 5.03988 5.0407Z" fill="white" />
                <path d="M5.75961 5.0407C6.95254 5.0407 7.91961 4.07364 7.91961 2.8807C7.91961 1.68777 6.95254 0.720703 5.75961 0.720703C4.56667 0.720703 3.59961 1.68777 3.59961 2.8807C3.59961 4.07364 4.56667 5.0407 5.75961 5.0407Z" fill="#0099B5" />
                <path d="M9.7911 4.17578L9.6543 4.58618L9.8631 4.65818" fill="white" />
                <path d="M9.79093 4.17578L9.92773 4.58618L9.71893 4.65818" fill="white" />
                <path d="M10.2024 4.47385L9.76984 4.47057L9.76589 4.6914" fill="white" />
                <path d="M10.2028 4.47341L9.8548 4.73034L9.7218 4.554" fill="white" />
                <path d="M9.38016 4.47341L9.7282 4.73034L9.8612 4.554" fill="white" />
                <path d="M9.38058 4.47385L9.81317 4.47057L9.81712 4.6914" fill="white" />
                <path d="M10.0446 4.95736L9.91405 4.54493L9.7028 4.60941" fill="white" />
                <path d="M10.0443 4.9567L9.69237 4.70509L9.81897 4.52411" fill="white" />
                <path d="M9.53679 4.9567L9.88869 4.70509L9.76209 4.52411" fill="white" />
                <path d="M9.53645 4.95736L9.66701 4.54493L9.87825 4.60941" fill="white" />
                <path d="M9.7911 2.44922L9.6543 2.85962L9.8631 2.93162" fill="white" />
                <path d="M9.79093 2.44922L9.92773 2.85962L9.71893 2.93162" fill="white" />
                <path d="M10.2024 2.74729L9.76984 2.74401L9.76589 2.96484" fill="white" />
                <path d="M10.2028 2.74685L9.8548 3.00377L9.7218 2.82744" fill="white" />
                <path d="M9.38016 2.74685L9.7282 3.00377L9.8612 2.82744" fill="white" />
                <path d="M9.38058 2.74729L9.81317 2.74401L9.81712 2.96484" fill="white" />
                <path d="M10.0446 3.2308L9.91405 2.81837L9.7028 2.88285" fill="white" />
                <path d="M10.0443 3.23014L9.69237 2.97853L9.81897 2.79755" fill="white" />
                <path d="M9.53679 3.23014L9.88869 2.97853L9.76209 2.79755" fill="white" />
                <path d="M9.53645 3.2308L9.66701 2.81837L9.87825 2.88285" fill="white" />
                <path d="M9.7911 0.720703L9.6543 1.1311L9.8631 1.2031" fill="white" />
                <path d="M9.79093 0.720703L9.92773 1.1311L9.71893 1.2031" fill="white" />
                <path d="M10.2024 1.01878L9.76984 1.01549L9.76589 1.23632" fill="white" />
                <path d="M10.2028 1.01833L9.8548 1.27526L9.7218 1.09893" fill="white" />
                <path d="M9.38016 1.01833L9.7282 1.27526L9.8612 1.09893" fill="white" />
                <path d="M9.38058 1.01878L9.81317 1.01549L9.81712 1.23632" fill="white" />
                <path d="M10.0446 1.50228L9.91405 1.08985L9.7028 1.15433" fill="white" />
                <path d="M10.0443 1.50162L9.69237 1.25001L9.81897 1.06903" fill="white" />
                <path d="M9.53679 1.50162L9.88869 1.25001L9.76209 1.06903" fill="white" />
                <path d="M9.53645 1.50228L9.66701 1.08985L9.87825 1.15433" fill="white" />
                <path d="M11.5186 4.17578L11.3818 4.58618L11.5906 4.65818" fill="white" />
                <path d="M11.5185 4.17578L11.6553 4.58618L11.4465 4.65818" fill="white" />
                <path d="M11.93 4.47385L11.4974 4.47057L11.4934 4.6914" fill="white" />
                <path d="M11.9304 4.47341L11.5823 4.73034L11.4493 4.554" fill="white" />
                <path d="M11.1077 4.47341L11.4557 4.73034L11.5887 4.554" fill="white" />
                <path d="M11.1081 4.47385L11.5407 4.47057L11.5447 4.6914" fill="white" />
                <path d="M11.7721 4.95736L11.6416 4.54493L11.4303 4.60941" fill="white" />
                <path d="M11.7718 4.9567L11.4199 4.70509L11.5465 4.52411" fill="white" />
                <path d="M11.2643 4.9567L11.6162 4.70509L11.4896 4.52411" fill="white" />
                <path d="M11.264 4.95736L11.3945 4.54493L11.6058 4.60941" fill="white" />
                <path d="M11.5186 2.44922L11.3818 2.85962L11.5906 2.93162" fill="white" />
                <path d="M11.5185 2.44922L11.6553 2.85962L11.4465 2.93162" fill="white" />
                <path d="M11.93 2.74729L11.4974 2.74401L11.4934 2.96484" fill="white" />
                <path d="M11.9304 2.74685L11.5823 3.00377L11.4493 2.82744" fill="white" />
                <path d="M11.1077 2.74685L11.4557 3.00377L11.5887 2.82744" fill="white" />
                <path d="M11.1081 2.74729L11.5407 2.74401L11.5447 2.96484" fill="white" />
                <path d="M11.7721 3.2308L11.6416 2.81837L11.4303 2.88285" fill="white" />
                <path d="M11.7718 3.23014L11.4199 2.97853L11.5465 2.79755" fill="white" />
                <path d="M11.2643 3.23014L11.6162 2.97853L11.4896 2.79755" fill="white" />
                <path d="M11.264 3.2308L11.3945 2.81837L11.6058 2.88285" fill="white" />
                <path d="M11.5186 0.720703L11.3818 1.1311L11.5906 1.2031" fill="white" />
                <path d="M11.5185 0.720703L11.6553 1.1311L11.4465 1.2031" fill="white" />
                <path d="M11.93 1.01878L11.4974 1.01549L11.4934 1.23632" fill="white" />
                <path d="M11.9304 1.01833L11.5823 1.27526L11.4493 1.09893" fill="white" />
                <path d="M11.1077 1.01833L11.4557 1.27526L11.5887 1.09893" fill="white" />
                <path d="M11.1081 1.01878L11.5407 1.01549L11.5447 1.23632" fill="white" />
                <path d="M11.7721 1.50228L11.6416 1.08985L11.4303 1.15433" fill="white" />
                <path d="M11.7718 1.50162L11.4199 1.25001L11.5465 1.06903" fill="white" />
                <path d="M11.2643 1.50162L11.6162 1.25001L11.4896 1.06903" fill="white" />
                <path d="M11.264 1.50228L11.3945 1.08985L11.6058 1.15433" fill="white" />
                <path d="M13.2472 4.17578L13.1104 4.58618L13.3192 4.65818" fill="white" />
                <path d="M13.247 4.17578L13.3838 4.58618L13.175 4.65818" fill="white" />
                <path d="M13.6585 4.47385L13.2259 4.47057L13.2219 4.6914" fill="white" />
                <path d="M13.6589 4.47341L13.3109 4.73034L13.1779 4.554" fill="white" />
                <path d="M12.8362 4.47341L13.1843 4.73034L13.3173 4.554" fill="white" />
                <path d="M12.8366 4.47385L13.2692 4.47057L13.2732 4.6914" fill="white" />
                <path d="M13.5007 4.95736L13.3701 4.54493L13.1589 4.60941" fill="white" />
                <path d="M13.5003 4.9567L13.1484 4.70509L13.275 4.52411" fill="white" />
                <path d="M12.9928 4.9567L13.3447 4.70509L13.2181 4.52411" fill="white" />
                <path d="M12.9925 4.95736L13.1231 4.54493L13.3343 4.60941" fill="white" />
                <path d="M13.2472 2.44922L13.1104 2.85962L13.3192 2.93162" fill="white" />
                <path d="M13.247 2.44922L13.3838 2.85962L13.175 2.93162" fill="white" />
                <path d="M13.6585 2.74729L13.2259 2.74401L13.2219 2.96484" fill="white" />
                <path d="M13.6589 2.74685L13.3109 3.00377L13.1779 2.82744" fill="white" />
                <path d="M12.8362 2.74685L13.1843 3.00377L13.3173 2.82744" fill="white" />
                <path d="M12.8366 2.74729L13.2692 2.74401L13.2732 2.96484" fill="white" />
                <path d="M13.5007 3.2308L13.3701 2.81837L13.1589 2.88285" fill="white" />
                <path d="M13.5003 3.23014L13.1484 2.97853L13.275 2.79755" fill="white" />
                <path d="M12.9928 3.23014L13.3447 2.97853L13.2181 2.79755" fill="white" />
                <path d="M12.9925 3.2308L13.1231 2.81837L13.3343 2.88285" fill="white" />
                <path d="M13.2472 0.720703L13.1104 1.1311L13.3192 1.2031" fill="white" />
                <path d="M13.247 0.720703L13.3838 1.1311L13.175 1.2031" fill="white" />
                <path d="M13.6585 1.01878L13.2259 1.01549L13.2219 1.23632" fill="white" />
                <path d="M13.6589 1.01833L13.3109 1.27526L13.1779 1.09893" fill="white" />
                <path d="M12.8362 1.01833L13.1843 1.27526L13.3173 1.09893" fill="white" />
                <path d="M12.8366 1.01878L13.2692 1.01549L13.2732 1.23632" fill="white" />
                <path d="M13.5007 1.50228L13.3701 1.08985L13.1589 1.15433" fill="white" />
                <path d="M13.5003 1.50162L13.1484 1.25001L13.275 1.06903" fill="white" />
                <path d="M12.9928 1.50162L13.3447 1.25001L13.2181 1.06903" fill="white" />
                <path d="M12.9925 1.50228L13.1231 1.08985L13.3343 1.15433" fill="white" />
                <path d="M6.33504 4.17578L6.19824 4.58618L6.40704 4.65818" fill="white" />
                <path d="M6.33488 4.17578L6.47168 4.58618L6.26288 4.65818" fill="white" />
                <path d="M6.74638 4.47385L6.31379 4.47057L6.30983 4.6914" fill="white" />
                <path d="M6.74679 4.47341L6.39875 4.73034L6.26575 4.554" fill="white" />
                <path d="M5.92411 4.47341L6.27215 4.73034L6.40515 4.554" fill="white" />
                <path d="M5.92452 4.47385L6.35711 4.47057L6.36106 4.6914" fill="white" />
                <path d="M6.58855 4.95736L6.45799 4.54493L6.24675 4.60941" fill="white" />
                <path d="M6.58821 4.9567L6.23631 4.70509L6.36291 4.52411" fill="white" />
                <path d="M6.08073 4.9567L6.43263 4.70509L6.30603 4.52411" fill="white" />
                <path d="M6.0804 4.95736L6.21095 4.54493L6.4222 4.60941" fill="white" />
                <path d="M8.06258 4.17578L7.92578 4.58618L8.13458 4.65818" fill="white" />
                <path d="M8.06242 4.17578L8.19922 4.58618L7.99042 4.65818" fill="white" />
                <path d="M8.47391 4.47385L8.04133 4.47057L8.03737 4.6914" fill="white" />
                <path d="M8.47433 4.47341L8.12629 4.73034L7.99329 4.554" fill="white" />
                <path d="M7.65165 4.47341L7.99969 4.73034L8.13269 4.554" fill="white" />
                <path d="M7.65206 4.47385L8.08465 4.47057L8.0886 4.6914" fill="white" />
                <path d="M8.31608 4.95736L8.18553 4.54493L7.97429 4.60941" fill="white" />
                <path d="M8.31575 4.9567L7.96385 4.70509L8.09045 4.52411" fill="white" />
                <path d="M7.80827 4.9567L8.16017 4.70509L8.03357 4.52411" fill="white" />
                <path d="M7.80794 4.95736L7.93849 4.54493L8.14974 4.60941" fill="white" />
                <path d="M8.06258 2.44922L7.92578 2.85962L8.13458 2.93162" fill="white" />
                <path d="M8.06242 2.44922L8.19922 2.85962L7.99042 2.93162" fill="white" />
                <path d="M8.47391 2.74729L8.04133 2.74401L8.03737 2.96484" fill="white" />
                <path d="M8.47433 2.74685L8.12629 3.00377L7.99329 2.82744" fill="white" />
                <path d="M7.65165 2.74685L7.99969 3.00377L8.13269 2.82744" fill="white" />
                <path d="M7.65206 2.74729L8.08465 2.74401L8.0886 2.96484" fill="white" />
                <path d="M8.31608 3.2308L8.18553 2.81837L7.97429 2.88285" fill="white" />
                <path d="M8.31575 3.23014L7.96385 2.97853L8.09045 2.79755" fill="white" />
                <path d="M7.80827 3.23014L8.16017 2.97853L8.03357 2.79755" fill="white" />
                <path d="M7.80794 3.2308L7.93849 2.81837L8.14974 2.88285" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_123_1297">
                    <rect width="24" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default FlagUz