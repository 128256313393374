import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './style.scss';
import { Pagination, Navigation } from 'swiper/modules';
import Product from '../Product';
import { useNavigate } from 'react-router-dom';
import { ProductsTypes } from '../../models';

interface Types {
    products: ProductsTypes[];
};

const Slider: React.FC<Types> = ({ products }) => {
    const navigate = useNavigate();
    return (
        <div className="container">
            <Swiper
                slidesPerView={products?.length > 4 ? 4.15 : products?.length}
                spaceBetween={30}
                loop={true}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                style={{ height: "auto", marginTop: 48 }}
            >
                {products.map((item: any) => (
                    <SwiperSlide key={item.id}>
                        <Product
                            name={item[`name_${localStorage.cur_lang}`]}
                            image={`${process.env.REACT_APP_API_BASE_URL}${item.image}`}
                            onClick={() =>
                                navigate(`/product/${item.id}`, {
                                    state: { product_id: item.id },
                                })
                            }
                            info={item.promotion ? `${item?.promotion?.amount} ${item?.promotion?.monetary_unit}` : ""}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}

export default Slider;

interface ImageTypes {
    images: {
        id: number;
        image: string;
        product: number;
    }[];
};

export const ProductSlider: React.FC<ImageTypes> = ({ images }) => {
    return (
        <div className="container">
            <Swiper
                slidesPerView={images?.length > 1 ? 1.05 : images?.length}
                spaceBetween={32}
                loop={true}
                navigation={false}
                modules={[Pagination, Navigation]}
                style={{ width: "100%" }}
            >
                {images?.map(item => (
                    <SwiperSlide key={item.id}>
                        <img src={`${process.env.REACT_APP_API_BASE_URL}${item.image}`} alt="" width={"100%"} height={738} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}