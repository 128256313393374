import * as uz_data from './uz.json';
import * as ru_data from './ru.json';
import * as en_data from './en.json';

let cur_lang = localStorage.cur_lang || 'ru';

setInterval(() => {
  cur_lang = localStorage.cur_lang || 'ru';
}, 1000)

if(!['ru', 'uz', 'en'].includes(cur_lang)) {
  cur_lang = 'ru';
}
const lang = {
  uz_data,
  ru_data,
  en_data
}

export const t = (text) => lang[`${cur_lang}_data`][text] ? (lang[`${cur_lang}_data`][text] || text) : text;

export const langName = (key, str = "name") => {
  return key[`${str}_${cur_lang}`];
};
