import React from 'react'

const Logo: React.FC = () => {
    return (
        <svg width="158" height="18" viewBox="0 0 158 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M51.1993 10.4644V7.53563H40.6782V2.95971H55.6162V0H37.65V17.9997H40.6785V10.4641M91.5228 15.0406V0H88.4948V17.9997H106.232V15.0406H91.5228ZM116.741 15.0406V10.4641H128.146V7.53563H116.741V2.95971H132.121V0H113.712V17.9997H132.615V15.0406H116.741ZM142.713 2.95943H151.079C152.456 2.95943 153.509 4.17982 153.509 5.73574C153.509 7.29139 152.456 8.48136 151.079 8.48136H142.713V2.95943ZM152.969 11.0137C154.831 10.1593 156.127 8.0847 156.127 5.73574C156.127 4.14912 155.641 2.80674 154.642 1.67791C153.67 0.54907 152.483 0 151.079 0H140.095V17.9997H142.713V11.4405H149.919L154.507 17.9997H157.827L152.969 11.0137ZM76.6456 10.4644V7.53563H66.1245V2.95971H81.0628V0H63.0962V17.9997H66.1247V10.4641H76.6456V10.4644ZM0.673828 2.9312H14.3378L8.1826 13.852H11.5464L19.352 0.000548781L19.3523 0H15.9888H2.63901L0.673828 2.9312ZM19.73 15.0688H6.06637L12.221 4.14803H8.85749L1.05184 17.9992L1.05157 17.9997V18L4.41505 17.9997H17.7648L19.73 15.0688ZM27.2103 18H30.1697V0H27.2103V18Z" fill="#FEFEFE" />
        </svg>
    )
}

export default Logo