import React from 'react'
import './style.scss'

interface Props {
    image: string;
    name: string;
    info?: string;
    onClick: () => void;
};

const Product: React.FC<Props> = ({ image, name, info, onClick }) => {
    return (
        <div className="product" onClick={onClick}>
            <div className="product-image">
                <img src={image} alt="" />
            </div>
            <div className="product-name">{name}</div>
            <span>{info}</span>
        </div>
    )
}

export default Product