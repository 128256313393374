// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product {
  width: 290px;
  display: flex;
  flex-direction: column;
  margin-bottom: 64px;
  cursor: pointer;
}
.product-image {
  width: 100%;
  height: 400px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
}
.product-image img {
  width: 180px;
  height: 250px;
  object-fit: contain;
}
.product-name {
  text-align: center;
  margin-top: 16px;
  font-size: 16px;
  font-weight: 500;
  color: #fefefe;
}
.product-name:hover {
  color: #f66e24;
}
.product span {
  text-align: center;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #898989;
}`, "",{"version":3,"sources":["webpack://./src/components/Product/style.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,eAAA;AACJ;AAAI;EACI,WAAA;EACA,aAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,oCAAA;AAER;AADQ;EACI,YAAA;EACA,aAAA;EACA,mBAAA;AAGZ;AAAI;EACI,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;AAER;AADQ;EACI,cAAA;AAGZ;AAAI;EACI,kBAAA;EACA,eAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;AAER","sourcesContent":[".product {\n    width: 290px;\n    display: flex;\n    flex-direction: column;\n    margin-bottom: 64px;\n    cursor: pointer;\n    &-image {\n        width: 100%;\n        height: 400px;\n        border-radius: 12px;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        background-color: #00000033;\n        & img {\n            width: 180px;\n            height: 250px;\n            object-fit: contain;\n        }\n    }\n    &-name {\n        text-align: center;\n        margin-top: 16px;\n        font-size: 16px;\n        font-weight: 500;\n        color: #fefefe;\n        &:hover {\n            color: #f66e24;\n        }\n    }\n    & span {\n        text-align: center;\n        margin-top: 8px;\n        font-size: 14px;\n        font-weight: 400;\n        color: #898989;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
