import React from 'react'

const Facebook: React.FC = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 12.3033C22 6.7467 17.5229 2.24219 12 2.24219C6.47715 2.24219 2 6.7467 2 12.3033C2 17.325 5.65684 21.4874 10.4375 22.2422V15.2116H7.89844V12.3033H10.4375V10.0867C10.4375 7.56515 11.9305 6.17231 14.2146 6.17231C15.3088 6.17231 16.4531 6.36882 16.4531 6.36882V8.8448H15.1922C13.95 8.8448 13.5625 9.62041 13.5625 10.4161V12.3033H16.3359L15.8926 15.2116H13.5625V22.2422C18.3432 21.4874 22 17.3252 22 12.3033Z" fill="#898989" />
        </svg>
    )
}

const Instagram: React.FC = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16 3.24219H8C5.23858 3.24219 3 5.48077 3 8.24219V16.2422C3 19.0036 5.23858 21.2422 8 21.2422H16C18.7614 21.2422 21 19.0036 21 16.2422V8.24219C21 5.48077 18.7614 3.24219 16 3.24219ZM19.25 16.2422C19.2445 18.0348 17.7926 19.4867 16 19.4922H8C6.20735 19.4867 4.75549 18.0348 4.75 16.2422V8.24219C4.75549 6.44954 6.20735 4.99768 8 4.99219H16C17.7926 4.99768 19.2445 6.44954 19.25 8.24219V16.2422ZM16.75 8.49219C17.3023 8.49219 17.75 8.04447 17.75 7.49219C17.75 6.93991 17.3023 6.49219 16.75 6.49219C16.1977 6.49219 15.75 6.93991 15.75 7.49219C15.75 8.04447 16.1977 8.49219 16.75 8.49219ZM12 7.74219C9.51472 7.74219 7.5 9.75691 7.5 12.2422C7.5 14.7275 9.51472 16.7422 12 16.7422C14.4853 16.7422 16.5 14.7275 16.5 12.2422C16.5027 11.0479 16.0294 9.90176 15.1849 9.05727C14.3404 8.21278 13.1943 7.73953 12 7.74219ZM9.25 12.2422C9.25 13.761 10.4812 14.9922 12 14.9922C13.5188 14.9922 14.75 13.761 14.75 12.2422C14.75 10.7234 13.5188 9.49219 12 9.49219C10.4812 9.49219 9.25 10.7234 9.25 12.2422Z" fill="#898989" />
        </svg>
    )
}

const Telegram: React.FC = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_327_217)">
                <path d="M21.7391 43.4783C33.7453 43.4783 43.4783 33.7453 43.4783 21.7391C43.4783 9.73294 33.7453 0 21.7391 0C9.73294 0 0 9.73294 0 21.7391C0 33.7453 9.73294 43.4783 21.7391 43.4783Z" fill="#898989" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.83977 21.5096C16.1772 18.7485 20.4031 16.9282 22.5175 16.0487C28.5547 13.5377 29.8092 13.1015 30.6269 13.0871C30.8067 13.0839 31.2088 13.1285 31.4693 13.3398C31.6892 13.5183 31.7497 13.7593 31.7786 13.9285C31.8076 14.0977 31.8437 14.4832 31.815 14.7843C31.4878 18.2218 30.0722 26.5636 29.3521 30.4137C29.0473 32.0428 28.4473 32.589 27.8664 32.6424C26.604 32.7586 25.6453 31.8081 24.4226 31.0066C22.5093 29.7524 21.4284 28.9717 19.5711 27.7478C17.4248 26.3334 18.8162 25.556 20.0394 24.2855C20.3595 23.9531 25.9218 18.8937 26.0295 18.4348C26.0429 18.3774 26.0554 18.1634 25.9283 18.0504C25.8012 17.9374 25.6136 17.9761 25.4782 18.0068C25.2863 18.0503 22.2297 20.0706 16.3085 24.0676C15.4409 24.6634 14.6551 24.9536 13.951 24.9384C13.1748 24.9217 11.6817 24.4996 10.5717 24.1388C9.21032 23.6962 8.1283 23.4622 8.22252 22.7107C8.27159 22.3192 8.81068 21.9188 9.83977 21.5096Z" fill="#2b2a29" />
            </g>
            <defs>
                <clipPath id="clip0_327_217">
                    <rect width="43.4783" height="43.4783" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

const YouTube: React.FC = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.5928 7.20301C21.4789 6.78041 21.2563 6.39501 20.9472 6.08518C20.6381 5.77534 20.2532 5.55187 19.8308 5.43701C18.2648 5.00701 11.9998 5.00001 11.9998 5.00001C11.9998 5.00001 5.73584 4.99301 4.16884 5.40401C3.74677 5.52415 3.36266 5.75078 3.05341 6.06214C2.74415 6.3735 2.52013 6.75913 2.40284 7.18201C1.98984 8.74801 1.98584 11.996 1.98584 11.996C1.98584 11.996 1.98184 15.26 2.39184 16.81C2.62184 17.667 3.29684 18.344 4.15484 18.575C5.73684 19.005 11.9848 19.012 11.9848 19.012C11.9848 19.012 18.2498 19.019 19.8158 18.609C20.2383 18.4943 20.6236 18.2714 20.9335 17.9622C21.2434 17.653 21.4672 17.2682 21.5828 16.846C21.9968 15.281 21.9998 12.034 21.9998 12.034C21.9998 12.034 22.0198 8.76901 21.5928 7.20301ZM9.99584 15.005L10.0008 9.00501L15.2078 12.01L9.99584 15.005Z" fill="#898989" />
        </svg>
    )
}

export const SocialLinks = { Facebook, Instagram, Telegram, YouTube };