import React from 'react';
import "./scss/styles";
import Routes from './components/Routes';

const App: React.FC = () => {
  
  return <Routes />;
}

export default App;
