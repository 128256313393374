import React, { useEffect, useState } from "react";
import "./style.scss";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { t } from "../../resources/t";
import Product from "../../components/Product";
import { useLocation, useNavigate } from "react-router-dom";
import { CategoryTypes, ProductsTypes } from "../../models";
import axios from "../../axios";
import Loading from "../../components/Loading";

const ProductsListPage: React.FC = () => {
  const navigate = useNavigate();
  const params = useLocation();

  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<CategoryTypes[]>([]);
  const [products, setProducts] = useState<ProductsTypes[]>([]);
  const [subcategoryDetail, setSubcategoryDetail] = useState<any>(null);

  useEffect(() => {
    setLoading(true);
    axios
      .get("/client/category_list/")
      .then((response) => {
        setCategories(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/client/subcategory_list/${params.state.subcategory_id}/`)
      .then((response) => {
        setSubcategoryDetail(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `/client/product_list/?category=${params.state.category_id}&subcategory=${params.state.subcategory_id}`
      )
      .then((response) => {
        setProducts(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  return (
    <>
      <Header />
      <div
        className="top-bgimage"
        style={{
          backgroundImage: subcategoryDetail?.image ?
            `url(${process.env.REACT_APP_API_BASE_URL}${subcategoryDetail?.image})`
            : require("../../assets/images/product-bg.png")
        }}
      >
        <div className="top-bgimage-title">{subcategoryDetail && subcategoryDetail[`name_${localStorage.cur_lang}`]}</div>
        {/* <div className="top-bgimage-descr">
          {t(
            "Широкий выбор техники для кухни: качество, технологии и удобство в одном."
          )}
        </div> */}
      </div>
      <div className="products container">
        {loading && <Loading />}
        <div className="products-left">
          <div className="products-left-title">{t("Категории")}</div>
          {categories.map((item: any) => (
            <span
              onClick={() =>
                navigate(`/categories/${item.id}`, {
                  state: { category_id: item.id },
                })
              }
            >
              {item[`name_${localStorage.cur_lang}`]}
            </span>
          ))}
        </div>
        <div className="products-right">
          {products.map((item: any) => (
            <Product
              key={item.id}
              name={item[`name_${localStorage.cur_lang}`]}
              image={
                item?.image
                  ? `${process.env.REACT_APP_API_BASE_URL}${item.image}`
                  : require("../../assets/images/product-img.png")
              }
              onClick={() =>
                navigate(`/product/${item.id}`, {
                  state: { product_id: item.id },
                })
              }
              info={""}
            />
          ))}
        </div>
      </div>
      <div className="bottom-bgimage">
        <div className="bottom-bgimage-title">
          {t("Откройте для себя свой стиль")}
        </div>
        <div className="bottom-bgimage-descr">
          {t(
            "Ziffler – современный, доступный бренд, который развивается и предлагает фирменную бытовую технику для Вашего дома."
          )}
        </div>
        <button>
          <span>{t("Подробнее")}</span>
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.46968 13.5292C5.32923 13.3886 5.25034 13.1979 5.25034 12.9992C5.25034 12.8004 5.32923 12.6098 5.46968 12.4692L9.43968 8.49918L5.46968 4.52918C5.396 4.46052 5.33689 4.37771 5.2959 4.28571C5.25491 4.19372 5.23287 4.0944 5.23109 3.9937C5.22931 3.893 5.24784 3.79297 5.28556 3.69958C5.32328 3.60619 5.37943 3.52136 5.45065 3.45014C5.52186 3.37892 5.6067 3.32278 5.70009 3.28505C5.79347 3.24733 5.8935 3.22881 5.99421 3.23059C6.09491 3.23236 6.19422 3.2544 6.28622 3.2954C6.37822 3.33639 6.46102 3.39549 6.52968 3.46918L11.0297 7.96918C11.1701 8.1098 11.249 8.30043 11.249 8.49918C11.249 8.69793 11.1701 8.88855 11.0297 9.02918L6.52968 13.5292C6.38906 13.6696 6.19843 13.7485 5.99968 13.7485C5.80093 13.7485 5.61031 13.6696 5.46968 13.5292Z"
              fill="#FEFEFE"
            />
          </svg>
        </button>
      </div>
      <Footer />
    </>
  );
};

export default ProductsListPage;
