import React from 'react'

const Search: React.FC = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.3973 20.6032L16.5804 15.7864C17.954 14.2067 18.6606 12.1554 18.5513 10.065C18.442 7.97447 17.5253 6.00809 15.9944 4.58032C14.4635 3.15255 12.4381 2.37493 10.3451 2.41138C8.25208 2.44784 6.25497 3.29551 4.77475 4.77573C3.29454 6.25594 2.44686 8.25305 2.41041 10.3461C2.37395 12.4391 3.15157 14.4645 4.57934 15.9954C6.00712 17.5262 7.9735 18.4429 10.064 18.5523C12.1545 18.6616 14.2057 17.955 15.7854 16.5814L20.6023 21.3982C20.7089 21.4976 20.8499 21.5517 20.9957 21.5491C21.1414 21.5465 21.2804 21.4875 21.3835 21.3845C21.4865 21.2814 21.5456 21.1424 21.5481 20.9966C21.5507 20.8509 21.4966 20.7099 21.3973 20.6032ZM3.56226 10.5007C3.56226 9.12863 3.96914 7.78733 4.73144 6.64647C5.49374 5.5056 6.57723 4.61641 7.84489 4.09132C9.11256 3.56624 10.5075 3.42885 11.8532 3.69654C13.1989 3.96422 14.4351 4.62496 15.4053 5.59518C16.3755 6.56541 17.0363 7.80155 17.304 9.1473C17.5716 10.493 17.4343 11.8879 16.9092 13.1556C16.3841 14.4233 15.4949 15.5068 14.354 16.2691C13.2132 17.0314 11.8719 17.4382 10.4998 17.4382C8.66051 17.436 6.89722 16.7044 5.59667 15.4038C4.29612 14.1033 3.56449 12.34 3.56226 10.5007Z" fill="#898989" />
        </svg>
    )
}

export default Search;