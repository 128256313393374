import React from 'react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import MainPage from '../../pages/MainPage';
import CategoriesPage from '../../pages/CategoriesPage';
import ProductsListPage from '../../pages/ProductsListPage';
import ProductDetailPage from '../../pages/ProductDetailPage';
import PromosPage from '../../pages/PromosPage';

const router = createBrowserRouter([
    {
        path: "/",
        element: <MainPage />
    },
    {
        path: "/products/:id",
        element: <ProductsListPage />
    },
    {
        path: "/categories",
        element: <CategoriesPage />
    },
    {
        path: "/categories/:id",
        element: <CategoriesPage />
    },
    {
        path: "/product/:id",
        element: <ProductDetailPage />
    },
    {
        path: "/promos",
        element: <PromosPage />
    },
]);

const Routes: React.FC = () => {
    return <RouterProvider router={router} />;
}

export default Routes