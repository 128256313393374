// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.products {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 100px 0 36px 0;
}
.products-left {
  display: flex;
  flex-direction: column;
  flex: 3 1;
}
.products-left-title {
  font-size: 24px;
  font-weight: 700;
  color: #fefefe;
  margin-bottom: 16px;
}
.products-left-active {
  color: #f66e24 !important;
}
.products-left span {
  margin-top: 16px;
  font-size: 16px;
  font-weight: 500;
  color: #898989;
  cursor: pointer;
}
.products-left span:hover {
  color: #f66e24;
}
.products-right {
  flex: 9 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}`, "",{"version":3,"sources":["webpack://./src/pages/ProductsListPage/style.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,8BAAA;EACA,uBAAA;AACJ;AAAI;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AAER;AADQ;EACI,eAAA;EACA,gBAAA;EACA,cAAA;EACA,mBAAA;AAGZ;AADQ;EACI,yBAAA;AAGZ;AADQ;EACI,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;AAGZ;AAFY;EACI,cAAA;AAIhB;AAAI;EACI,SAAA;EACA,aAAA;EACA,8BAAA;EACA,eAAA;AAER","sourcesContent":[".products {\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    padding: 100px 0 36px 0;\n    &-left {\n        display: flex;\n        flex-direction: column;\n        flex: 3;\n        &-title {\n            font-size: 24px;\n            font-weight: 700;\n            color: #fefefe;\n            margin-bottom: 16px;\n        }\n        &-active {\n            color: #f66e24 !important;\n        }\n        & span {\n            margin-top: 16px;\n            font-size: 16px;\n            font-weight: 500;\n            color: #898989;\n            cursor: pointer;\n            &:hover {\n                color: #f66e24;\n            }\n        }\n    }\n    &-right {\n        flex: 9;\n        display: flex;\n        justify-content: space-between;\n        flex-wrap: wrap;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
